

























































import Vue from "vue";
import Component from "vue-class-component";
import { Auth } from "@/store/modules";
import { User } from "@/store/auth";

@Component
export default class Home extends Vue {
  city = '';
  phone = '';
  loading = false;
  error = '';
  step = 1;
  step1completed = false;
  step2completed = false;
  stripeLoading = false;
  paymentsEnabled = false;
  firstLoading = true;
  dialog = false;

  get stripeSessionId() {
    return this.$route.query.session_id as string;
  }

  async mounted() {
    if (!Auth.loggedIn) {
      this.$router.push('/login')
    }

    await Auth.refreshAccountData();

    const user = Auth.user as User;
    this.city = user.signUpCity || '';
    this.phone = user.phone || '';

    if (this.phone && this.city) {
      this.step1completed = true;
      this.step = 2;
    }

    if (user.stripeAccountId && user.paymentsEnabled) {
      this.step2completed = true;
      this.paymentsEnabled = true;
      this.step = 3;
    }

    if (this.stripeSessionId) {
      this.step = 2;
      this.finalizeStripe();
    }

    if (this.$route.query.stripeFailed === 'true') {
      this.step = 2;
    }

    if (this.$route.query.step) {
      this.step = Number(this.$route.query.step);
    }

    if (this.step === 2 && !user.paymentsEnabled) {
      this.dialog = true;
    }

    this.firstLoading = false;
  }

  async continueButtonClick() {
    if (this.step === 1) {
      this.updateCity();
    } else if (this.step === 2) {
      this.step = 3;
    } else if (this.step === 3) {
      await Auth.refreshAccountData();
      const user = Auth.user as User;
      if (!user.paymentsEnabled) {
        this.dialog = true
      } else {
        this.$router.push('/help')
      }
    }
  }

  async updateCity() {
    this.loading = true;
    try {
      await Auth.updateDetails({ signUpCity: this.city, phone: this.phone });
      this.error = '';
      this.step = 2;
      this.step1completed = true;
    } catch (err) {
      this.error = err.message;
    }
    this.loading = false;
  }

  async stripeSetup() {
    this.stripeLoading = true;
    Auth.setupStripe();
  }

  async finalizeStripe() {
    this.stripeLoading = true;
    try {
      await Auth.finalizeStripeSetup({ sessionId: this.stripeSessionId });
      this.paymentsEnabled = true;
      this.step2completed = true;
      this.step = 3;
    } catch (err) {
      this.error = 'Failed to finalize Stripe setup at the moment. Please try again later.';
    }
    this.stripeLoading = false;
  }
}
